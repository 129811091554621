import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useConfirm } from "material-ui-confirm";
import { ServiciiService } from "../../_services";
import { serviciiActions } from "../../_store";
import Header from "../../components/Header";
import { Box, Typography, useTheme } from "@mui/material";
import DOMPurify from "dompurify";
import "react-quill/dist/quill.snow.css";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  roRO,
} from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { DeleteOutline } from "@mui/icons-material";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

function ServiciiList({ match }) {
  const confirm = useConfirm();
  const theme = useTheme();
  const dispatch = useDispatch();
  const colors = tokens(theme.palette.mode);
  const { path } = useLocation(); // match;
  const [currentPage, setCurrentPage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);

  const [servicii, setServicii] = useState(null);

  useEffect(() => {
    ServiciiService.getAll().then((x) => setServicii(x.data));
    //      dispatch(serviciiActions.getAll());
    //setServicii(useSelector(x => x.servicii));
    //setProduct(servicii);
  }, []);

  // const { servicii } = useSelector(x => x.servicii);
  ////console.log({servicii});

  const onRowsSelectionHandler = (ids) => {
    const selectedRowsData = ids.map((id) =>
      servicii.find((row) => row.id === id)
    );
    console.log(selectedRowsData[0].id, selectedRowsData[0], ids.id);
    console.log("Servicii : ", servicii);
    setActivePage(selectedRowsData[0], selectedRowsData[0].id);
    console.log("currentPage", currentPage);
  };
  const setActivePage = (Page, index) => {
    setCurrentPage(Page);
    setCurrentIndex(index);
  };

  const deleteServicii = (id) => {
    confirm({
      title: "Esti Sigur",
      description: `Se va șterge definitiv Serviciul ${id}.`,
      confirmationText: "da",
      cancellationText: "renunta",
    })
      .then(() => {
        setServicii(
          servicii.map((x) => {
            if (x.id === id) {
              x.isDeleting = true;
            }
            return x;
          })
        );
        ServiciiService.remove(id).then(() => {
          setServicii((servicii) => servicii.filter((x) => x.id !== id));
        });
      })
      .catch(() => {
        //console.log("Deletion cancelled.")
      });
  };

  const columns = [
    { field: "id", headerName: "Id", hide: true },
    {
      field: "title",
      headerName: "Titlu",
      width: 200,
      cellClassName: "name-column--cell",
    },
    {
      field: "img",
      headerName: "Poza",
      width: 90,
      renderCell: (params: any) => {
        return (
          <>
            {params.row.img ? (
              <img
                src={
                  process.env.REACT_APP_API_URL +
                  "/products/image/" +
                  params.row.img
                }
                className="img-fluid rounded"
                width="60px"
              />
            ) : (
              <img src="assets/logo.png" className="img-fluid" width="60px" />
            )}
          </>
        );
      },
    },

    {
      field: "active",
      headerName: "Status",
      width: 90,
      renderCell: (params: any) => {
        return (
          <>
            {params.row.active ? (
              <div className="badge badge-primary">Activ</div>
            ) : (
              <div className="badge badge-danger">Inactiv</div>
            )}
          </>
        );
      },
    },

    {
      field: "action",
      headerName: "",
      width: 100,
      renderCell: (params: any) => {
        return (
          <>
            <Link to={`/servicii/write`} state={params.row}>
              <button className="btn btn-sm btn-primary mr-1">
                <i className="fas fa-edit"></i>
              </button>
            </Link>
            <DeleteOutline
              className="btn btn-xs btn-sm btn-danger"
              style={{ width: "30px", height: "30px" }}
              onClick={() => deleteServicii(params.row.id)}
            />
          </>
        );
      },
    },
  ];

  function getStatus(params) {
    //console.log(params);
    return `${!params.row.active || "Activ"} ${params.row.active || "Inactiv"}`;
  }

  return (
    <Box m="20px">
      <div>
        <h1>Servicii</h1>

        <Link to={`/servicii/write`} className="btn btn-sm btn-success mb-2">
          Adăugați un Serviciu
        </Link>

        {servicii && (
          <Box
            m="8px 0 0 0"
            height="60vh"
            width="90%"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiChackbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
            }}
          >
            <DataGrid
              rows={servicii}
              columns={columns}
              pageSize={5}
              localeText={roRO.components.MuiDataGrid.defaultProps.localeText}
              components={{ Toolbar: CustomToolbar }}
              onSelectionModelChange={(ids) => {
                //console.log(ids);
                //console.log(ids[0]);
                onRowsSelectionHandler(ids);
                // //console.log(params);
              }}
            />
          </Box>
        )}
      </div>

      <div className="col-md-6">
        {currentPage ? (
          <div>
            <h4>Serviciu</h4>
            <div>
              <label>
                <strong>Titlu:</strong>
              </label>{" "}
              {currentPage.title}
            </div>
            <div>
              <label></label>{" "}
              {currentPage.img && (
                <img
                  src={
                    process.env.REACT_APP_API_URL +
                    "/test/files/" +
                    currentPage.img
                  }
                  className="img-responsive img-fluid"
                  width="100%"
                />
              )}
            </div>
            <div>
              <label>
                <strong>Descriere:</strong>
              </label>{" "}
              {/*<p
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(currentPage.description),
                }}
              ></p> */}
              <div className="ql-snow w-full mt-16 "> <div className="ql-editor" dangerouslySetInnerHTML={{__html: currentPage.description}} /> </div> 
            </div>
            <div>
              <label>
                <strong>Status:</strong>
              </label>{" "}
              {currentPage.active ? "Publicat" : "Pending"}
            </div>

            <Link
              className="badge badge-warning"
              to={`/servicii/write`}
              state={currentPage}
            >
              <i className="fas fa-edit"></i>
            </Link>
          </div>
        ) : (
          <div>
            <br />
            <p>Vă rugăm să faceți clic pe un titlu...</p>
          </div>
        )}
      </div>
    </Box>
  );
}

export { ServiciiList };
