import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useConfirm } from "material-ui-confirm";
import { ProjectService } from "../../_services";
//import { projectActions } from '../../_store';
import Header from "../../components/Header";
import { Box, Typography, useTheme } from "@mui/material";
import DOMPurify from "dompurify";
import "react-quill/dist/quill.snow.css";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  roRO,
} from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { DeleteOutline } from "@mui/icons-material";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

function ProjectList({ match }) {
  const confirm = useConfirm();
  const theme = useTheme();
  const dispatch = useDispatch();
  const colors = tokens(theme.palette.mode);
  const { path } = useLocation(); // match;
  const [currentPage, setCurrentPage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);

  const [project, setProject] = useState(null);

  useEffect(() => {
    ProjectService.getAll().then((x) => setProject(x.data));
    //      dispatch(projectActions.getAll());
    //setProject(useSelector(x => x.project));
    //setProduct(project);
  }, []);

  // const { project } = useSelector(x => x.project);
  ////console.log({project});

  const onRowsSelectionHandler = (ids) => {
    const selectedRowsData = ids.map((id) =>
      project.find((row) => row.id === id)
    );
    console.log(selectedRowsData, selectedRowsData[0], ids);
    console.log("Project : ", project);
    setActivePage(selectedRowsData[0], selectedRowsData[0].id);
    console.log("currentPage", currentPage);
  };
  const setActivePage = (Page, index) => {
    setCurrentPage(Page);
    setCurrentIndex(index);
  };

  const deleteProject = (id) => {
    confirm({
      title: "Esti Sigur",
      description: `Se va șterge definitiv Proiectul ${id}.`,
      confirmationText: "da",
      cancellationText: "renunta",
    })
      .then(() => {
        setProject(
          project.map((x) => {
            if (x.id === id) {
              x.isDeleting = true;
            }
            return x;
          })
        );
        ProjectService.remove(id).then(() => {
          setProject((project) => project.filter((x) => x.id !== id));
        });
      })
      .catch(() => {
        //console.log("Deletion cancelled.")
      });
  };

  const columns = [
    { field: "id", headerName: "Id", hide: true },
    {
      field: "title",
      headerName: "Titlu",
      width: 200,
      cellClassName: "name-column--cell",
    },
    {
      field: "img",
      headerName: "Poza",
      width: 90,
      renderCell: (params: any) => {
        return (
          <>
            {params.row.coverImage ? (
              <img
                src={
                  process.env.REACT_APP_API_URL +
                  "/products/image/" +
                  params.row.coverImage
                }
                className="img-fluid rounded"
                width="60px"
              />
            ) : (
              <img src="assets/logo.png" className="img-fluid" width="60px" />
            )}
          </>
        );
      },
    },

    {
      field: "active",
      headerName: "Status",
      width: 90,
      renderCell: (params: any) => {
        return (
          <>
            {params.row.active ? (
              <div className="badge badge-primary">Activ</div>
            ) : (
              <div className="badge badge-danger">Inactiv</div>
            )}
          </>
        );
      },
    },

    {
      field: "action",
      headerName: "",
      width: 100,
      renderCell: (params: any) => {
        return (
          <>
            <Link to={`/project/write`} state={params.row}>
              <button className="btn btn-sm btn-primary mr-1">
                <i className="fas fa-edit"></i>
              </button>
            </Link>
            <DeleteOutline
              className="btn btn-xs btn-sm btn-danger"
              style={{ width: "30px", height: "30px" }}
              onClick={() => deleteProject(params.row.id)}
            />
          </>
        );
      },
    },
  ];

  function getStatus(params) {
    //console.log(params);
    return `${!params.row.active || "Activ"} ${params.row.active || "Inactiv"}`;
  }

  return (
    <Box m="20px">
      <div>
        <h1>Project</h1>

        <Link to={`/project/write`} className="btn btn-sm btn-success mb-2">
          Adăugați un Proiect
        </Link>

        {project && (
          <Box
            m="8px 0 0 0"
            height="60vh"
            width="90%"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiChackbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
            }}
          >
            <DataGrid
              rows={project}
              columns={columns}
              pageSize={5}
              localeText={roRO.components.MuiDataGrid.defaultProps.localeText}
              components={{ Toolbar: CustomToolbar }}
              onSelectionModelChange={(ids) => {
                //console.log(ids);
                //console.log(ids[0]);
                onRowsSelectionHandler(ids);
                // //console.log(params);
              }}
            />
          </Box>
        )}
      </div>

      <div className="col-md-6">
        {currentPage ? (
          <div>
            <h4>Proiect</h4>
            <div>
              <label>
                <strong>Titlu:</strong>
              </label>{" "}
              {currentPage.title}
            </div>
            <div>
              <label>
                <strong>Rezumat:</strong>
              </label>{" "}
              <p
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(currentPage.shortDescription),
                }}
              ></p>
            </div>
            <div>
              <label></label>{" "}
              {currentPage.coverImage && (
                <img
                  src={
                    process.env.REACT_APP_API_URL +
                    "/test/files/" +
                    currentPage.coverImage
                  }
                  className="img-responsive img-fluid"
                  width="100%"
                />
              )}
            </div>
            <div>
              <label>
                <strong>Descriere:</strong>
              </label>{" "}
             {/*  <p
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(currentPage.description),
                }}
              ></p>*/}

<div className="ql-snow w-full mt-16 "> <div className="ql-editor" dangerouslySetInnerHTML={{__html: currentPage.description}} /> </div> 


            </div>
            {currentPage.moreImages &&
            <ImageList
              sx={{ width: 500, height: 400 }}
              cols={3}
              rowHeight={164}
            >
              {currentPage.moreImages.map((item) => (
                <ImageListItem key={item}>
                  <img
                    srcSet={`${process.env.REACT_APP_API_URL + "/products/image/" + item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    src={`${process.env.REACT_APP_API_URL + "/products/image/" + item}?w=164&h=164&fit=crop&auto=format`}
                    alt={item}
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          }
            <div>
              <label>
                <strong>Status:</strong>
              </label>{" "}
              {currentPage.active ? "Publicat" : "Pending"}
            </div>

            <Link
              className="badge badge-warning"
              to={`/project/write`}
              state={currentPage}
            >
              <i className="fas fa-edit"></i>
            </Link>
          </div>
        ) : (
          <div>
            <br />
            <p>Vă rugăm să faceți clic pe un titlu...</p>
          </div>
        )}
      </div>
    </Box>
  );
}

export { ProjectList };
